import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import { Avatar as BaseAvatar, Dropdown, Layout, Menu } from "antd";
import {
  TrophyOutlined,
  DashboardOutlined,
  UnorderedListOutlined,
  SearchOutlined,
  StarOutlined,
  UserOutlined,
  EditOutlined,
  SoundOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import styled from "@emotion/styled";

import { CreateCampaignIcon } from "./CreateCampaignIcon";
import { CreateListIcon } from "./CreateListIcon";
import { SidebarHeaderDropDown } from "./SidebarHeaderDropDown";
import { Paragraph, SmallParagraph } from "../../Typography";
import ArrowIcon from "../../../assets/icons/arrow.svg";

import { State } from "../../../utils/store";
import { useAppSelector } from "../../../utils/hooks";
import { UpgradeYourPlanTypes, upgradeYourPlan } from "../../UpgradeYourPlan";
import { Segment } from "../../../utils/Segment";

const rootSubmenuKeys = {
  campaigns: "campaigns",
  drafts: "drafts",
  lists: "lists",
};

export const Sidebar = () => {
  const { pathname } = useLocation();

  const [collapsed, setCollapse] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [openKeys, setOpenKeys] = useState<string[]>([]);

  const { selectedClient } = useAppSelector((state: State) => state.clients);
  const { user } = useAppSelector((state: State) => state.auth);
  const { campaigns, draftCampaigns } = useAppSelector(
    (state: State) => state.campaigns
  );
  const { lists } = useAppSelector((state: State) => state.lists);

  const onCollapse = () => {
    setCollapse((prevState) => !prevState);
  };

  const handleOnVisibleChange = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const onOpenMenuItemChange = (keys: string[]) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    const submenuKeys = Object.keys(rootSubmenuKeys);
    if (submenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  let orderedCampaigns = [...campaigns];

  // Campaigns must be sorted by publishedAt, in case a draft was created a long time ago
  // but not published until recently.
  orderedCampaigns.sort(function (a, b) {
    // A published campaign will always have publishedAt
    // TODO: Create different types of Campaign interfaces:
    // DraftCampaign --> publishedAt: null
    // PublishedCampaign --> publishedAt: Date
    if (a.publishedAt && b.publishedAt) {
      let t1 = new Date(a.publishedAt).getTime();
      let t2 = new Date(b.createdAt).getTime();

      return t2 - t1;
    }
    return 0;
  });

  let orderedDrafts = [...draftCampaigns];
  orderedDrafts.sort(function (a, b) {
    let t1 = new Date(a.createdAt).getTime();
    let t2 = new Date(b.createdAt).getTime();

    return t2 - t1;
  });

  let orderedLists = [...lists];
  orderedLists.sort(function (a, b) {
    return b.order - a.order;
  });

  let username: any = "";
  if (user) {
    username = user.firstName;
    if (!username) username = user.displayName;
    else username += " " + user.lastName;
  }

  const handleUpgradeYourPlanOnClick = () => {
    upgradeYourPlan(
      "Do you want to upgrade your plan? Contact us!",
      UpgradeYourPlanTypes.Discovery,
      { source: "sidebar", client: selectedClient?.uuid }
    );
    Segment.analytics?.track("open-upgrade-your-plan", {
      clientUUID: selectedClient?.uuid,
      source: "sidebar",
    });
  };

  return (
    <BaseSidebar
      trigger={null}
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
    >
      <nav style={{ overflowY: "auto", overflowX: "hidden" }}>
        <Menu
          theme="light"
          selectedKeys={[pathname]}
          mode="inline"
          openKeys={openKeys}
          onOpenChange={onOpenMenuItemChange}
        >
          <TriggerButton onClick={onCollapse}>
            {collapsed ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
          </TriggerButton>

          <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
            <NavLink to={`/${selectedClient?.slug}/dashboard`}>
              Dashboard
            </NavLink>
          </Menu.Item>
          <Menu.SubMenu
            key={rootSubmenuKeys.campaigns}
            icon={<SoundOutlined />}
            title={
              <CampaignMenuTitle id="sidebarCampaignButton">
                <span>Campaigns </span>
                <CreateCampaignIcon />
              </CampaignMenuTitle>
            }
          >
            {/* This is the campaign container where all the new campaign will be displayed */}
            <CampaignsContainer key="g1" className="sidebar-campaign-section">
              {orderedCampaigns.map((campaign, index) => (
                <Menu.Item
                  key={`/${selectedClient?.slug}/campaign/golden-basket/${campaign.slug}`}
                  id={`campaign${index}`}
                >
                  <NavLink
                    to={`/${selectedClient?.slug}/campaign/golden-basket/${campaign.slug}`}
                    title={campaign.name}
                  >
                    {campaign.name}
                  </NavLink>
                </Menu.Item>
              ))}
            </CampaignsContainer>
          </Menu.SubMenu>
          <Menu.SubMenu
            key={rootSubmenuKeys.drafts}
            icon={<EditOutlined />}
            title={
              <CampaignMenuTitle id="sidebarDraftsButton">
                Drafts
              </CampaignMenuTitle>
            }
          >
            {/* This is the campaign container where all the drafts will be displayed */}
            <CampaignsContainer key="g2" className="sidebar-drafts-section">
              {orderedDrafts.map((draft, index) => (
                <Menu.Item
                  key={`/${selectedClient?.slug}/new-campaign/${draft.uuid}`}
                  id={`draft${index}`}
                >
                  <NavLink
                    to={`/${selectedClient?.slug}/new-campaign/${draft.uuid}`}
                    title={draft.name}
                  >
                    {draft.name}
                  </NavLink>
                </Menu.Item>
              ))}
            </CampaignsContainer>
          </Menu.SubMenu>
          <Menu.SubMenu
            key={rootSubmenuKeys.lists}
            icon={<UnorderedListOutlined />}
            title={
              <CampaignMenuTitle id="sidebarListButton">
                <span>Lists</span>
                <CreateListIcon />
              </CampaignMenuTitle>
            }
          >
            {/* This is the campaign container where all the new campaign will be displayed */}
            <CampaignsContainer key="g3" className="sidebar-campaign-section">
              {orderedLists.map((list, index) => (
                <Menu.Item
                  key={`/${selectedClient?.slug}/list/${list.slug}`}
                  id={`list${index}`}
                >
                  <NavLink
                    to={`/${selectedClient?.slug}/list/${list.slug}`}
                    title={list.name}
                  >
                    {list.name}
                  </NavLink>
                </Menu.Item>
              ))}
            </CampaignsContainer>
          </Menu.SubMenu>
          <Menu.Item
            key={`/${selectedClient?.slug}/real-time-search`}
            icon={<SearchOutlined />}
            id="sidebarRealTimeSearchSection"
          >
            <NavLink to={`/${selectedClient?.slug}/real-time-search`}>
              Real-time search
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key={`/${selectedClient?.slug}/scott`}
            icon={<MessageOutlined />}
            id="sidebarScottSection"
          >
            <NavLink to={`/${selectedClient?.slug}/scott`}>
              Scott (beta)
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key={`/${selectedClient?.slug}/favourites`}
            icon={<StarOutlined />}
          >
            <FlexNavLink
              to={`/${selectedClient?.slug}/favourites`}
              id="sidebarFavouriteSection"
            >
              My favourites
            </FlexNavLink>
          </Menu.Item>
        </Menu>
      </nav>
      <Menu
        style={{ marginTop: "auto" }}
        theme="light"
        mode="inline"
        selectedKeys={[pathname]}
      >
        <Menu.Item
          key="upgrade-your-plan"
          icon={<TrophyOutlined />}
          id="upgradeYourPlan"
          onClick={handleUpgradeYourPlanOnClick}
        >
          Upgrade your plan
        </Menu.Item>
      </Menu>

      <Dropdown
        overlay={
          <SidebarHeaderDropDown
            hasMultipleClients={user?.hasMultipleClients}
            userEmail={user?.email}
            userRole={selectedClient?.userRole}
            handleOnVisibleChange={handleOnVisibleChange}
            userIsStaff={user?.isStaff}
            selectedClientSlug={selectedClient?.slug}
          />
        }
        trigger={["click"]}
        placement="topLeft"
        visible={dropdownVisible}
        onVisibleChange={handleOnVisibleChange}
      >
        <SidebarHeader>
          {user?.photoURL ? (
            <Avatar size="large" src={user.photoURL} alt="avatar" />
          ) : (
            <Avatar size="large" icon={<UserOutlined />} />
          )}

          <UserInfo collapsed={collapsed}>
            <CompanyParagraph>
              {selectedClient && selectedClient.name}
            </CompanyParagraph>
            <NameParagraph>{user && username}</NameParagraph>
          </UserInfo>
          <Arrow dropdownVisible={dropdownVisible} />
        </SidebarHeader>
      </Dropdown>
    </BaseSidebar>
  );
};

const BaseSidebar = styled(Layout.Sider)`
  position: sticky;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100vh;

  color: var(--font-color-white);
  background: var(--white);

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
  }
`;

const SidebarHeader = styled.div`
  height: 8rem;
  padding-left: 1rem;
  // border-bottom: var(--sidebar-header-border);
  background: var(--background-color-tertiery);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  cursor: pointer;

  &: hover {
    background: var(--background-color-secondary);
  }
`;

const Arrow = styled.div<{ dropdownVisible: boolean }>`
  width: 0.9rem;
  height: 1.8rem;
  margin-left: auto;
  margin-right: 1rem;
  background: none;
  background-image: url(${ArrowIcon});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  vertical-align: middle;
  transition-duration: 0.3s;
  transform: ${(props) =>
    props.dropdownVisible ? "rotate(90deg)" : "rotate(-90deg)"};
  outline: none;
  filter: grayscale(1);
`;

const Avatar = styled(BaseAvatar)`
  flex: 0 0 4rem;
  background: var(--background-campaign-avatar);
`;

const CompanyParagraph = styled(SmallParagraph)`
  color: var(--font-color-black);
`;

const NameParagraph = styled(Paragraph)`
  font-weight: var(--font-weight-bold);
`;

const UserInfo = styled.div<{ collapsed: boolean }>`
  height: 3rem;
  color: var(--font-color-black);
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: ${(props) => (props.collapsed ? "none" : "flex")};

  p {
    margin: 0;
    padding: 0;
  }
`;

// Small workaround to make the visual aspect of the campaigns to be displayed as a submenu
// Creating a menu, that will be a placeholder, and rendering the campaigns as submenus
// And then hiding the menu
const CampaignsContainer = styled(Menu.ItemGroup)`
  max-height: 170rem;

  .ant-menu-item-group-title {
    display: none;
  }
`;

const TriggerButton = styled.div`
  height: 8rem;
  width: 100%;
  background-color: var(--layout-sider-background);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

const CampaignMenuTitle = styled.span`
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
`;

const FlexNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
`;
